import classNames from 'classnames';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import React, { useRef } from 'react';
import useFadeUp from '@hooks/animation/useFadeUp';
import { GridColumn, Grid } from '@layouts/Grid';
import { GridLayout } from '@layouts/Grid/Grid.types';
import SectionWrapper from '@presentation/SectionWrapper';
import formatDate from '@utils/formatDate';
import formatTitle from '@utils/formatTitle';
import IconChevronDown from '@icons/IconChevronDown';
const ArticleBanner = ({ authorAvatar, authorName, authorTitle, authorUrl, backgroundImage, backText, backUrl, date, perex, tag, title, }) => {
    const containerRef = useRef(null);
    const metaProps = useFadeUp({ ref: containerRef, delay: 0.1 });
    const titleProps = useFadeUp({ ref: containerRef, delay: 0.2 });
    const authorProps = useFadeUp({ ref: containerRef, delay: 0.3 });
    const bannerClassName = classNames({
        'template-banner': true,
        blog: true,
        'template-banner--thin': true,
        'template-banner--extended-spacing': true,
        greyscale: true,
    });
    return (<section ref={containerRef} className={bannerClassName}>
      {backgroundImage && (<div className="template-banner__bg">
          <Image fluid={backgroundImage}/>
        </div>)}

      <div className="site-wide grid-container grid-container--full">
        <div className="grid-column">
          <div className="template-banner__content-wrapper">
            {backText && backUrl && (<Link className="back-button" to={backUrl}>
                <IconChevronDown />
                {backText}
              </Link>)}
            {(tag || date) && (<motion.div className="template-banner__meta" {...metaProps}>
                {tag && (<span className="template-banner__meta-category">{tag}</span>)}
                {date && tag != 'Resource' && (<>
                    &nbsp;&bull;&nbsp;
                    {formatDate(date, {
        month: 'short',
    })}
                    &nbsp;
                    {formatDate(date, {
        day: 'numeric',
    })}
                    &#44;&nbsp;
                    {formatDate(date, {
        year: 'numeric',
    })}
                  </>)}
              </motion.div>)}

            {title && (<motion.h1 className="template-banner__title h2-styling" {...titleProps}>
                {formatTitle(title)}
              </motion.h1>)}

            {authorName && (<motion.span className="template-banner__author" {...authorProps}>
                <Link className="template-banner__author-name" to={authorUrl || '/'}>
                  {authorAvatar && (<div className="template-banner__author-image">
                      <Image fixed={authorAvatar}/>
                    </div>)}
                  {authorName}
                </Link>
                {authorTitle && (<span className="template-banner__author-separator">,</span>)}
                {authorTitle && (<div className="template-banner__author-role">
                    {authorTitle}
                  </div>)}
              </motion.span>)}

            {perex && (<SectionWrapper className="vert-margin-top-50" margin={false}>
                <Grid layout={GridLayout.FOUR}>
                  <GridColumn>
                    <div className="content-area summary-area">
                      <p>{perex}</p>
                    </div>
                  </GridColumn>
                </Grid>
              </SectionWrapper>)}
          </div>
        </div>
      </div>
    </section>);
};
export default ArticleBanner;
