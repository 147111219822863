import React from 'react';
import { FormProvider } from '@presentation/Form/FormProvider';
import ResourceDownloadFormComponent from '@presentation/ResourceDownloadForm';
const ResourceDownloadForm = ({ data, }) => {
    const articleType = data.elements?.article_types?.value?.[0]?.codename;
    if (articleType !== 'report' && articleType !== 'resource') {
        return null;
    }
    const downloadAsset = data.elements?.download_asset?.value?.[0]?.url;
    const targetSite = data.elements?.form_target_site?.value?.[0]?.codename || 'xperience';
    const formCodename = getFormCodename(data);
    const kontentPardotUrl = data.elements?.pardot_url_kontent?.value || null;
    return (<FormProvider formName={formCodename} additionalFormState={{
        PageUrl: data.url,
        ResourceUrl: downloadAsset,
    }}>
      <ResourceDownloadFormComponent targetSite={targetSite} kontentPardotUrl={kontentPardotUrl} downloadAsset={downloadAsset} downloadMessage={data.elements?.download_message?.value} downloadRedirect={data.elements?.download_redirect?.value} formTitle={data.elements?.form_title?.value} submitText={data.elements?.submit_text?.value} formColour={data.elements?.form_colour?.value?.[0]?.codename} downloadButtonText={data.elements?.download_button_text?.value}/>
    </FormProvider>);
};
function getFormCodename(data) {
    let formName = 'ResourceDownloadForm';
    if (data.elements?.form_target_site?.value?.[0]?.codename === 'kontent') {
        if (data.elements?.form_variant_kontent?.value?.[0]?.codename === 'long') {
            formName = 'ResourceDownloadFormKontentLong';
        }
        else {
            formName = 'ResourceDownloadFormKontentShort';
        }
    }
    else if (data.elements?.form_codename_xperience?.value) {
        formName = data.elements?.form_codename_xperience?.value;
    }
    return formName;
}
export default ResourceDownloadForm;
