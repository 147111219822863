import { graphql } from 'gatsby';
import React from 'react';
import LinkedItem from '@connected/LinkedItem';
import ResourceDownloadForm from '@connected/ResourceDownloadForm';
import RichContentWithComponents from '@connected/RichContentWithComponents';
import Sections from '@connected/Sections';
import SEO from '@connected/SEO';
import CardList from '@layouts/CardList';
import ArticleBanner from '@presentation/ArticleBanner';
import SocialShare from '@presentation/SocialShare';
const ArticlePage = ({ data }) => {
    const articleData = data.kontentItemArticle;
    if (!articleData) {
        return null;
    }
    return (<>
      <SEO {...articleData.metadata} image={articleData.elements?.og_image?.value?.[0]?.url} url={articleData.url}/>
      {renderDetail()}
      {renderArticles()}
    </>);
    function renderDetail() {
        let backText = '';
        let backUrl = '';
        const articleBackTarget = articleData.elements?.article_back_target
            ?.linked_items?.[0];
        backText = articleData.elements?.article_back_text?.value;
        backUrl = articleBackTarget?.url;
        if (backText === '' || backUrl === undefined) {
            const masterData = data.kontentItemMaster;
            if (backText === '') {
                backText = masterData.elements?.article_back_text?.value;
            }
            if (backUrl === undefined) {
                const masterBackTarget = masterData.elements?.article_back_target
                    ?.linked_items?.[0];
                backUrl = masterBackTarget?.url;
            }
        }
        const authorData = articleData.elements?.authors?.linked_items?.[0];
        const dateStr = articleData.elements?.start_date?.value || new Date();
        const date = new Date(dateStr);
        const articleType = articleData.elements?.article_types?.value?.[0]?.name;
        return (<>
        <ArticleBanner authorAvatar={authorData?.elements?.avatar?.value?.[0]?.fixed} authorName={authorData?.elements?.standard_content__title?.value} authorTitle={authorData?.elements?.title?.value} authorUrl={authorData?.url} backgroundImage={articleData.elements?.backgroundImage?.value?.[0]?.fluid} backText={backText} backUrl={backUrl} date={date} foregroundImage={articleData.elements?.foregroundImage?.value?.[0]?.fluid} perex={articleData.elements?.standard_content__summary?.value} tag={articleType} title={articleData.elements?.standard_content__title?.value || ''}/>

        <div className="article-area">
          <RichContentWithComponents content={articleData.elements?.content?.resolvedData?.html || ''} images={articleData.elements?.content?.images || []} linkedItems={articleData.elements?.content?.linked_items || []} links={articleData.elements?.content?.links || []}/>
        </div>

        <ResourceDownloadForm data={articleData}/>

        <SocialShare shareData={{
            title: articleData.metadata?.title,
            url: articleData.url || '/',
        }} title={`Share this ${articleType?.toLocaleLowerCase()}`}/>

        <Sections {...articleData.elements?.sections__sections}/>
      </>);
    }
    function renderArticles() {
        const articleType = articleData.elements?.article_types?.value?.[0]?.name;
        const relatedArticleData = data.allKontentItemArticle.nodes;
        if (!relatedArticleData?.length) {
            return null;
        }
        // Basic pluralization; may need to be updated if multi-culture or more complex plural rules are needed.
        const pluralized = articleType?.endsWith('s')
            ? articleType
            : `${articleType?.toLocaleLowerCase()}s`;
        return (<CardList title={`Other ${pluralized} you may like`}>
        {relatedArticleData.map(article => (<LinkedItem key={article.system?.id || ''} item={article}/>))}
      </CardList>);
    }
};
export const query = graphql `
  query ArticlePageQuery($slug: String!, $type: String!) {
    kontentItemArticle(elements: { slug: { value: { eq: $slug } } }) {
      ...SEOFragment
      url
      elements {
        og_image: metadata__page_image {
          value {
            url
          }
        }
        article_types {
          value {
            codename
            name
          }
        }
        authors {
          linked_items {
            ... on KontentItemProfile {
              elements {
                avatar {
                  value {
                    fixed(height: 32, width: 32, fit: "crop") {
                      ...KontentAssetFixed_withWebp
                    }
                  }
                }
                standard_content__title {
                  value
                }
                title {
                  value
                }
              }
              url
            }
          }
        }
        backgroundImage: banner {
          value {
            fluid(maxWidth: 1920) {
              ...KontentAssetFluid_withWebp
            }
          }
        }
        foregroundImage: banner {
          value {
            fluid(maxWidth: 1200) {
              ...KontentAssetFluid_withWebp
            }
          }
        }
        standard_content__summary {
          value
        }
        content {
          ...KontentRichTextElementFragment
        }
        download_asset {
          value {
            url
          }
        }
        download_button_text {
          value
        }
        download_message {
          value
        }
        download_redirect {
          value
        }
        form_variant_kontent {
          value {
            codename
          }
        }
        form_target_site {
          value {
            codename
          }
        }
        form_codename_xperience {
          value
        }
        pardot_url_kontent {
          value
        }
        form_title {
          value
        }
        form_colour {
          value {
            codename
          }
        }
        sections__sections {
          linked_items {
            ...LinkedItemsFragment
          }
        }
        standard_content__title {
          value
        }
        standard_content__summary {
          value
        }
        start_date {
          value
        }
        submit_text {
          value
        }
        article_back_target {
          linked_items {
            ... on NodeWithUrl {
              url
            }
          }
        }
        article_back_text {
          value
        }
      }
    }
    kontentItemMaster {
      elements {
        article_back_target {
          linked_items {
            ... on NodeWithUrl {
              url
            }
          }
        }
        article_back_text {
          value
        }
      }
    }
    allKontentItemArticle(
      filter: {
        elements: {
          article_types: { value: { elemMatch: { codename: { eq: $type } } } }
          slug: { value: { ne: $slug } }
        }
      }
      limit: 3
      sort: { fields: elements___start_date___value, order: DESC }
    ) {
      nodes {
        ...LinkedItemsFragment
      }
    }
  }
`;
export default ArticlePage;
