import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useStaticQuery, graphql } from 'gatsby';
import React, { useState, useRef } from 'react';
import useFadeUp from '@hooks/animation/useFadeUp';
import IconFacebook from '@icons/IconFacebook';
import IconLinkedIn from '@icons/IconLinkedIn';
import IconShare from '@icons/IconShare';
import IconTwitter from '@icons/IconTwitter';
import { Grid, GridColumn } from '@layouts/Grid';
import { GridLayout } from '@layouts/Grid/Grid.types';
import { getButtonClassName } from '@presentation/Button';
import { ButtonVariant } from '@presentation/Button/Button.types';
import SectionWrapper from '@presentation/SectionWrapper';
import formatTitle from '@utils/formatTitle';
const SocialShare = ({ shareData, title }) => {
    const containerRef = useRef(null);
    const titleProps = useFadeUp({ ref: containerRef, delay: 0.1 });
    const btnProps = useFadeUp({ ref: containerRef, delay: 0.2 });
    const [isActive, setIsActive] = useState(false);
    const { site: { siteMetadata: { origin }, }, } = useStaticQuery(graphql `
    {
      site {
        siteMetadata {
          origin
        }
      }
    }
  `);
    const btnClasses = getButtonClassName(ButtonVariant.OrangeSolid, false, true);
    const containerClassName = classNames({
        'share share--orange': true,
        'is-active': isActive,
    });
    const buttonClassName = classNames({
        // eslint-disable-next-line @typescript-eslint/camelcase
        share__button: true,
        [btnClasses]: true,
    });
    const socialLinkClassName = classNames({
        'share__social-link': true,
        [btnClasses]: true,
    });
    // Construct data to be shared.
    return (<SectionWrapper>
      <Grid layout={GridLayout.FULL_INDENT}>
        <GridColumn>
          <div ref={containerRef} className={containerClassName}>
            <motion.h3 {...titleProps}>{formatTitle(title)}</motion.h3>

            <hr />

            <div className="share__social">
              <motion.button className={buttonClassName} onClick={event => {
        event.preventDefault();
        // Attempt to share with the Web Share API, if not supported then show icons.
        share(shareData).catch(() => {
            // Set active state.
            setIsActive(!isActive);
        });
    }} {...btnProps}>
                <IconShare />
              </motion.button>

              <div className={socialLinkClassName} style={{ '--stagger': '0.1s' }}>
                <a href={getFacebookUrl(shareData, origin)} target="_blank" rel="noopener noreferrer">
                  <IconFacebook />
                </a>
              </div>

              <div className={socialLinkClassName} style={{ '--stagger': '0.2s' }}>
                <a href={getTwitterUrl(shareData, origin)} target="_blank" rel="noopener noreferrer">
                  <IconTwitter />
                </a>
              </div>

              <div className={socialLinkClassName} style={{ '--stagger': '0.3s' }}>
                <a href={getLinkedInUrl(shareData, origin)} target="_blank" rel="noopener noreferrer">
                  <IconLinkedIn />
                </a>
              </div>
            </div>
          </div>
        </GridColumn>
      </Grid>
    </SectionWrapper>);
};
export default SocialShare;
/**
 * Attempt to share using the Web Share API.
 * @param data The share data
 */
function share(data) {
    // Check for Level 2 support.
    /*if (navigator.share && navigator.canShare && navigator.canShare(data)) {
      return navigator.share(data);
    }
  
    // Check for Level 1 support.
    if (navigator.share) {
      return navigator.share(data);
    }*/
    // No support so reject.
    return Promise.reject();
}
function getFacebookUrl(data, origin) {
    const url = new URL(data.url || '/', origin);
    return `https://www.facebook.com/sharer/sharer.php?u=${url.href}`;
}
function getLinkedInUrl(data, origin) {
    const url = new URL(data.url || '/', origin);
    return `https://www.linkedin.com/shareArticle?title=${data.title}&url=${url.href}`;
}
function getTwitterUrl(data, origin) {
    const url = new URL(data.url || '/', origin);
    return `https://twitter.com/intent/tweet?text=${data.title}&url=${url.href}&via=Kentico`;
}
