import React from 'react';
import Form from '@presentation/Form';
import { useFormContext } from '@presentation/Form/FormProvider';
import getKontentPardotUrl from '@utils/getKontentPardotUrl';
const ResourceDownloadForm = ({ targetSite, kontentPardotUrl, downloadAsset, downloadMessage, downloadRedirect, formTitle, formColour, submitText, downloadButtonText, }) => {
    const formContext = useFormContext();
    if (!formContext) {
        return null;
    }
    const { createFormData, resetFormState, setConfirmation, setConfirmationCta, setErrorMessage, setFormLoading, validateForm, setPardotIframe, formState, } = formContext;
    return (<Form title={formTitle ? formTitle : 'Download the report'} submitText={submitText} onSubmit={handleSubmit} formColour={formColour} downloadButtonText={downloadButtonText} targetSite={targetSite}/>);
    async function handleSubmit(event) {
        setFormLoading(true);
        // Prevent default form submission.
        event.preventDefault();
        // Validate current form state.
        if (!(await validateForm())) {
            setFormLoading(false);
            return;
        }
        try {
            if (targetSite === 'kontent') {
                kontentPardotUrl = await getKontentPardotUrl(kontentPardotUrl, formState);
                setPardotIframe(kontentPardotUrl);
            }
            else {
                // Construct new FormData from the form element.
                const formData = createFormData();
                // Post form data to API.
                const url = `${process.env.GATSBY_XPERIENCE_API}/forms`;
                const res = await fetch(url, {
                    body: formData,
                    cache: 'no-cache',
                    credentials: 'include',
                    method: 'POST',
                    mode: 'cors',
                });
                // If the request failed then show error message.
                if (!res.ok) {
                    setFormLoading(false);
                    setErrorMessage('Could not submit the form data.');
                    return;
                }
            }
            // Handle displaying content.
            if (!downloadMessage) {
                downloadMessage = 'Thank you for your interest!';
            }
            if (downloadMessage) {
                setFormLoading(false);
                setConfirmation(downloadMessage);
                // Including an asset is optional as there may be an email triggered with a download.
                if (downloadAsset) {
                    setConfirmationCta(downloadAsset);
                }
                if (downloadRedirect) {
                    setConfirmationCta(downloadRedirect);
                }
                return;
            }
            // Handle redirecting if no confirmation text has been set.
            if (downloadRedirect) {
                setConfirmationCta(downloadRedirect);
            }
            // Default to resetting the form state.
            setFormLoading(false);
            resetFormState();
        }
        catch (error) {
            console.log(error);
            setFormLoading(false);
            setErrorMessage('Could not submit the form data.');
        }
    }
};
export default ResourceDownloadForm;
